@tailwind base;
@tailwind components;
@tailwind utilities;

.floating-form-field {
  @apply relative border-none;
}
.floating-form-field input {
  @apply block w-full appearance-none rounded-lg border-none p-4 text-gray-900 placeholder-gray-500;
}
.floating-form-field label {
  @apply absolute top-0 origin-0 bg-transparent p-4 text-gray-500 duration-300;
}
.floating-form-field input:focus-within ~ label,
.floating-form-field input:not(:placeholder-shown) ~ label {
  @apply z-0 ml-3 translate-y-1 scale-75 transform px-1 py-0;
}
.floating-form-field input:focus,
.floating-form-field input:not(:placeholder-shown) {
  @apply pt-6 pb-2 ring-0;
}
.without-ring {
  @apply outline-transparent ring-transparent focus:outline-transparent focus:ring-0 focus:ring-transparent focus:ring-offset-0;
}

body {
  @apply min-h-screen overflow-x-hidden bg-gray-50 sm:overflow-hidden;
}

.slider-card-featured {
  @apply xl:!min-w-[288px];
}

.slider-card {
  @apply xl:!min-w-[236px];
}
